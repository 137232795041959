import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Lottie from "react-lottie";
import './Aboutcss.css';
import landing2 from "../LottieJson/trimmed-lottieline.json";
import eazrlogo from "../LottieJson/eazrLogo.json";

const About = () => {
  const dataUsedFeatures = [
    {
      title: (
        <>
          <h1 className='font-bold'>
            What We're About ?
          </h1>
        </>
      ),
      desc: (
        <>
          <p>
            Think of it like this – we've got this cool 'Buy Now, Pay Later' thing going on, especially for medical stuff. So, no more stressing over immediate bills when you need healthcare.
          </p>
        </>
      ),
    },
    {
      title: (
        <>
          <h1 className='font-bold'>
            How We Roll ?
          </h1>
        </>
      ),
      desc: (
        <>
          <p>
            We're blending the best of tech with our Indian roots. All your doctor visits, medicines, and other health-related costs? They get bundled into one chill, bi-weekly bill. No fuss, no maze.
          </p>
        </>
      ),
    },
    {
      title: (
        <>
          <h1 className='font-bold'>
            Big Dreams! ?
          </h1>
        </>
      ),
      desc: (
        <>
          <p>
            Based in bustling Mumbai but dreaming big, worldwide style. Our goal? To be the go-to name when folks think of hassle-free health payments. We're mixing tradition with tech, all for your health and convenience! 🚀🌏🌡
          </p>
        </>
      ),
    },

  ]

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: landing2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const eazrOptions = {
    loop: false,
    autoplay: true,
    animationData: eazrlogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  const [ToogleAccordion, setToogleAccordion] = useState(0)

  const setToogleAccordionWrap = (e) => {
    if (e == ToogleAccordion) {
      setToogleAccordion(-1)
    } else {
      setToogleAccordion(e)
    }
  }


  const dataTeam = [
    {
      img: './../images/profile/sarabjeetsir.png',
      name: "Sarabjeet Singh",
      alias: "Idea Incubator",
      job: "Founder, CEO",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/vaibhav_sir.png',
      name: "Vaibhav Patil",
      alias: "Hype Master",
      job: "Co-Founder, Head Marketing",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/manish_sir.png',
      name: "Manish Rabadhia",
      alias: "Operation Overlord",
      job: "Head Business Operations",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/hitesh_sir.png',
      name: "Hitesh Ahuja",
      alias: "Tech Guru",
      job: "Head Technologies",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/kunjal_mam.png',
      name: "Kunjal K Muddar",
      alias: "Buddy Builder",
      job: "Head Partnerships",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/shweta.png',
      name: "Shweta Malusare",
      alias: "People's Paladine",
      job: "HR Manager",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/simran_mam.png',
      name: "Simran",
      alias: "Pixel Perfectionist",
      job: "Senior UI/UX Designer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/navin.png',
      name: "Navin Gupta",
      alias: "Meme Machine",
      job: "Media Content Strateagist",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/kamlesh.png',
      name: "Kamlesh Upadhyay",
      alias: "Code Commander",
      job: "Full Stack Developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/sakshi.png',
      name: "Sakshi Singh",
      alias: "Command Central",
      job: "Executive Asistant",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/shraddha.png',
      name: "Shraddha Kedare",
      alias: "Algo Alchemist",
      job: "Python Developer AI/ML",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },

    {
      img: './../images/profile/dhruvi.png',
      name: "Dhruvi Vasani",
      alias: "Blueprint Boss",
      job: "Sr. Associate- Op. Management",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },

    {
      img: './../images/profile/dipika.png',
      name: "Dipika Gupta",
      alias: "Hashtag Hero",
      job: "Social Media Coordinator",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/ashish.png',
      name: "Ashish Kamble",
      alias: "Motion Maestro",
      job: "Audio & Video Editor",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/anil_sir.png',
      name: "Anil Ahuja",
      alias: "Cascade Coder",
      job: "Senior Software Developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/dhir.png',
      name: "Dhir Jain",
      alias: "Insight Apprentice",
      job: "Market Research Trainee",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/sushant.png',
      name: "Sushant Pagam",
      alias: "Widget Weaver",
      job: "Flutter developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/abhinav.png',
      name: "Abhinav Rawat",
      alias: "Data Druid",
      job: "Jr. Software Developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/Shubhamrewale.png',
      name: "Shubham Rewale",
      alias: "Module Magician",
      job: "Associate Software Developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/aram.png',
      name: "Arham Momin",
      alias: "Backend Behemoth",
      job: "Backend Developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/nishant.png',
      name: "Nishant Jadhav",
      alias: "Canvas Crafter",
      job: "UI/UX Designer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/vishal.png',
      name: "Vishal Patra",
      alias: "Code Charmer",
      job: "Software Developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/siddhesh.png',
      name: "Siddhesh Salaskar",
      alias: "Flutter Frescoist",
      job: "Flutter Developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/shivam.png',
      name: "Shivam Gupta",
      alias: "Layout Legend",
      job: "Frontend Interface Developer",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
    {
      img: './../images/profile/blankimage.png',
      name: "You",
      alias: "Team Player",
      job: "Your Skillset",
      facebook: "#!",
      linkedin: "#!",
      twitter: "#!"
    },
  ]
  return (
    <Fragment>
      <section className='pt-16 pb-0'>
        <Container>

          <div className="text-center mb-8 ">
            <h2 className="font-medium mb-5">
              <span className="inline-block sm:block text__18 ">EAZR DIGIPAYMENTS PRIVATE LIMITED</span>
              <span className="inline-block text__64 sm:text__24">
                Where Tech Pulse Syncs with Health Rhythms 🚀💓🌡️ <br />
              </span>
              {/* <span className=" inline-block sm:block text__18">
                {" "} 
                EAZR DIGIPAYMENTS PRIVATE LIMITED
              </span> */}
            </h2>
            <p className='text__18'>We're not just some corporate gig – we're a crew supercharged by the latest tech and some seriously cool ideas. You know the whole 'Buy Now, Pay Later' thing? Yep, that's our jam, especially when it comes to health stuff. No more waiting or stressing over medical bills, we got you! All your health buys from different places? We roll them into one easy-peasy bill every two weeks. Even though we're vibing in Mumbai, we've got our eyes on the global prize. With Eazr, it's all about making healthcare payments a breeze.</p>
          </div>

        </Container>

        <div className="line">
          <Lottie
            style={{ height: "150%" }}
            options={defaultOptions}
            // height={200}
            // width={370}
            className="lottieribbontop"

          />
        </div>
      </section>

      <section className="mb-2">
        <Container>
          <Row className="gap-y-6">
            <Col className="pt-2 md:order-2 my-auto pt-24" md={6}>
              <Lottie
                style={{ height: "100%", width: "75%" }}
                // className="lg:h-[202px] md:h-[325px] sm:h-[300px] w-full lg:w-[40%] md:mx-auto mt-5"
                className="eazraboutlogo"
                options={eazrOptions}
              // height={332}
              // width={415}
              />
            </Col>
            <Col className="order-1 md:my-auto" md={6}>
              {/* <h3 className="text__48 font-semibold mb-2">Where Tech Pulse Syncs with Health Rhythms 🚀</h3>
              <p className="text__18 text-Mgray mb-10">Making sense of the cool stuff we do.</p> */}

              <div className="flex flex-wrap gap-3">
                {dataUsedFeatures.map((obj, i) => {
                  return (
                    <div
                      className={
                        "p-4 rounded-2xl w-full cursor-pointer " +
                        (ToogleAccordion == i
                          ? "bg-[#FAFAFA]"
                          : "border border-solid border-[#F5F5F5]")
                      }
                      onClick={() => setToogleAccordionWrap(i)}
                    >
                      <div className="flex items-center justify-between">
                        <h5 className="font-medium text__20">{obj.title}</h5>
                        <img
                          src="./../images/majesticons_chevron-down-line.svg"
                          className={
                            "transition-all duration-500 " +
                            (ToogleAccordion == i ? "rotate-180" : "")
                          }
                          alt=""
                        />
                      </div>
                      <div
                        className={
                          "py-2 " + (ToogleAccordion == i ? "block" : "hidden")
                        }
                      >
                        <p className="text__16">{obj.desc}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section>
        <Container>
          <div className="text-center mb-12">
            <h3 className='text__32 font-medium mb-2'>The Eazr Heart & Hardware Crew: Bridging Health & Tech</h3>
            <p className='text__18 text-Mgray'>Our multi-disciplinary team has extensive experience with combine creative, technical and strategic talent.</p>
          </div>

          <Row className='text-center gap-y-6'>
            {
              dataTeam.map((obj) => {
                return <Col className='col-6' md={6} lg={3}>
                  <div className="rounded-[12px] w-full sm:w-[180px] h-[180px] mb-3 mx-auto bg-[##6b5cc214] overflow-hidden">
                    <img src={obj.img} className='object-cover w-full h-full rounded-full' alt="" />
                  </div>
                  <h5 className='font-medium text__20 mb-1'>{obj.name}</h5>
                  <p className='font-light text__18 mb-1 font-sans text-Mblue'>{obj.alias}</p>
                  <p className='text__14 text-Mgray mb-3'>{obj.job}</p>
                  {/* <div className="flex items-center gap-2 justify-center">
                    <a href={obj.facebook}><img src="./../images/sos (4).svg" alt="" /></a>
                    <a href={obj.linkedin}><img src="./../images/sos (5).svg" alt="" /></a>
                    <a href={obj.twitter}><img src="./../images/sos (1).svg" alt="" /></a>
                  </div> */}
                </Col>
              })
            }
          </Row>

          <Row className='text-center gap-y-6 bg-Mbluelight py-5 rounded-3xl mt-4'>
            <Col>
              <h2 className='text__24 mb-2'>WE CAN'T WAIT TO MEET YOU!</h2>
              <h1 className='text__64 mb-3' style={{ fontFamily: "Nayuki" }}>Join our team</h1>
              <h3><button> <a href='https://forms.gle/sU79sEpshUWEaosR9' className='inline-block cursor-pointer text-center font-medium text__16 text-white !py-[15px] !px-[28px] bg-Mblue !border-Mblue btnClass md:w-auto w-full'>Apply Now</a></button></h3>
            </Col>
          </Row>
        </Container>
      </section>


    </Fragment>
  )
}

export default About
