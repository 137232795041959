import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { LogoIcon } from "../Icon/Icon";
import CalFooter from "./CalFooter";

const Footer = () => {
  return (
    <Fragment>
      <section className="pb-6 bg-[#F8F8F8]">
        <Container>
          <Row className="mb-4">
            <Col className="mb-4 mb-lg-0" lg={3}>
              <div className="flex items-center gap-2 mb-3">
                <LogoIcon />
                {/* <span className='font-medium text__20 text-Mblue'>Ticket</span> */}
              </div>
              <p className="text__16 text-Mlightblack">
                India karega health se no compromise! Let's empower a
                healthier future, one payment at a time with eazr.{" "}
              </p>
            </Col>
            <Col
              className="col-6 mb-4 mb-lg-0"
              lg={{ span: 2, offset: 1 }}
            >
              <h5 className="font-medium text__16 mb-3 text-[#2960de]">
                Company
              </h5>
              <div className="flex flex-wrap gap-2 font-medium text__16">
                {/* <NavLink to="/how-it-works" className='inline-block w-full text-Mlightblack'>Services</NavLink> */}
                <NavLink
                  to="/about"
                  className="inline-block w-full text-Mlightblack"
                >
                  About Us
                </NavLink>
                <NavLink
                  to="/help"
                  className="inline-block w-full text-Mlightblack"
                >
                  Help
                </NavLink>
                <NavLink
                  to="/contact"
                  className="inline-block w-full text-Mlightblack"
                >
                  Contact Us
                </NavLink>
                {/* <NavLink to="/about" className='inline-block w-full text-Mlightblack'>Our team</NavLink> */}
              </div>
            </Col>
            <Col className="col-6 mb-4 mb-lg-0" lg={2}>
              <h5 className="font-medium text__16 mb-3 text-[#2960de]">
                Explore
              </h5>
              <div className="flex flex-wrap gap-2 font-medium text__16">
                {/* <NavLink to="/how-it-works" className='inline-block w-full text-Mlightblack'>Services</NavLink> */}
                <NavLink
                  to="https://web.eazr.in/"
                  className="inline-block w-full text-Mlightblack"
                >
                  Register As Merchant
                </NavLink>
                <NavLink
                  to="https://partner.eazr.in/"
                  className="inline-block w-full text-Mlightblack"
                >
                  Merchant Login
                </NavLink>
                <NavLink
                  to="/BlogPage"
                  className="inline-block w-full text-Mlightblack"
                >
                  ArthShaala
                </NavLink>
                {/* <NavLink to="/about" className='inline-block w-full text-Mlightblack'>Our team</NavLink> */}
              </div>
            </Col>

            {/* <NavLink to="/faq" className='inline-block w-full text-Mlightblack'>FAQs</NavLink> */}

            <Col className="mb-4 mb-lg-0" lg={4}>
              <h5 className="font-medium text__16 mb-3 text-Mlightblack">
                Subscribe For Newsletter
              </h5>
              <div className="flex items-center gap-3">
                <input
                  type="text"
                  className="w-full h-[46px] border-l-0 border-b-[1px] border-Mlightblack text__14 bg-transparent outline-none focus:outline-none active:outline-none"
                  placeholder="Enter your email address"
                />
                <button className="font-medium text-[14px] bg-Mblue text-white px-4 py-2 rounded-full">
                  Submit
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="mb-10">
              {/* <h5 className='font-medium text__16 mb-3 text-[rgb(163,163,163)]'>Policies</h5> */}
              <div className="flex flex-wrap gap-1 font-medium text__15 md:gap-2 ">
                <NavLink
                  to="/privacyandpolicy"
                  className="text-Mlightblack"
                >
                  Privacy Policy
                </NavLink>{" "}
                |
                <NavLink
                  to="/grievancepolicy"
                  className="text-Mlightblack"
                >
                  Grievance Redressal Policy
                </NavLink>{" "}
                |
                <NavLink
                  to="/responsiblepolicy"
                  className="text-Mlightblack"
                >
                  Responsible Disclosure Policy
                </NavLink>{" "}
                |
                <NavLink
                  to="/informationpolicy"
                  className="text-Mlightblack"
                >
                  Information Security Policy
                </NavLink>{" "}
                |
                <NavLink
                  to="/termsandcondition"
                  className="text-Mlightblack"
                >
                  General Terms & Conditions
                </NavLink>{" "}
                |
                <NavLink to="/dpoterms" className="text-Mlightblack">
                  DPO Terms & Conditions
                </NavLink>{" "}
                |
                <NavLink
                  to="/insuranceterms"
                  className="text-Mlightblack"
                >
                  Insurance Terms & Conditions
                </NavLink>{" "}
                |
                <NavLink
                  to="/fintechassociation"
                  className="text-Mlightblack"
                >
                  Fintech Association For Consumer Empowerment
                </NavLink>{" "}
                |
                <NavLink
                  to="/ethicalcode"
                  className="text-Mlightblack"
                >
                  Ethical Code Of Conduct
                </NavLink>
                |
                <NavLink
                  to="/refund&cancellation"
                  className="text-Mlightblack"
                >
                  Refund & Cancellation Policy
                </NavLink>
              </div>
            </div>
          </Row>
          <hr />

          <CalFooter />

          <div className="relative flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-between gap-1">
            <p className="text__16 text-[#A3A3A3]">
              @ 2023 Eazr Digipayments Private Limited.
              <br /> All Rights Reserved.
            </p>

            <div className="flex justify-center items-center gap-3 lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 w-full lg:w-auto">
              <a href="https://twitter.com/eazrapp">
                <img src="./../images/sos (1).svg" alt="twitter" />
              </a>
              {/* <a href="#!">
                                <img src="./../images/sos (2).svg" alt="youtube" />
                            </a> */}
              <a href="https://www.instagram.com/eazr.app/">
                <img src="./../images/sos (3).svg" alt="instagram" />
              </a>
              <a href="https://www.facebook.com/EazrApp">
                <img src="./../images/sos (4).svg" alt="facebook" />
              </a>
              <a href="https://www.linkedin.com/company/eazr">
                <img src="./../images/sos (5).svg" alt="linkedin" />
              </a>
            </div>

            <div className="flex items-center gap-3">
              <NavLink
                to="/termsandcondition"
                className="font-medium text__16 text-[#525252]"
              >
                Terms & Conditions
              </NavLink>
              <NavLink
                to="/privacyandpolicy"
                className="font-medium text__16 text-[#525252]"
              >
                Privacy Policy
              </NavLink>
            </div>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default Footer;
